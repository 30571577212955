<template>
  <v-container>
    <div>
      <v-btn color="primary" @click="back" class="noprint mr-3">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
    </div>
    <div class="mt-3">
      <iframe
        :src="url"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="800px"
      ></iframe>
    </div>
  </v-container>
</template>

<script>
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import moment from "moment";

export default {
  name: "journal-settlement-external",
  props: {
    id: Number,
  },
  data() {
    return {
      journal: {},
      url: "",
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async getJournal() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("settlementExternal/getJournal", this.id)
        .then(response => {
          this.journal = response.data;
          this.createPdf();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      if (value === null) {
        return "";
      }
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    createListAccount() {
      let array = [];
      this.journal.accounts.forEach(x => {
        array.push([
          {
            content: `${x.accountName}\n${x.desc}`,
            styles: { valign: "middle", halign: "left" },
          },
          { content: this.formatPrice(x.debit), styles: { valign: "middle", halign: "right" } },
          { content: this.formatPrice(x.credit), styles: { valign: "middle", halign: "right" } },
        ]);
      });
      return array;
    },
    createPdf() {
      const doc = new jsPDF();

      doc.setFont("Times", "bold");
      doc.setFontSize(16);
      doc.text("JOURNAL", 5, 10);
      doc.line(5, 11, 33, 11);
      doc.setFontSize(12);
      doc.text("PT. GRAHA SUMBER PRIMA ELEKTRONIK", 5, 20);
      doc.text("No.", 120, 20);
      doc.text(`: ${this.journal.invoiceNumber}`, 130, 20);
      doc.text("Date", 120, 30);
      doc.text(`: ${moment(this.journal.date).format("DD-MM-yyyy")}`, 130, 30);
      doc.text("Curr", 120, 40);
      doc.text(`: ${this.journal.currency}`, 130, 40);

      doc.autoTable({
        margin: { left: 5, right: 5 },
        startY: 50,
        theme: "plain",
        tableLineWidth: 0.1,
        tableLineColor: "black",
        columnStyles: {
          0: { cellWidth: 90 },
        },
        head: [
          [
            {
              content: "ACCOUNT NAME & DESCRIPTION",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: "Debit",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: "Credit",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
          ],
        ],
        body: [
          ...this.createListAccount(),
          [
            {
              content: "TOTAL",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: `${this.formatPrice(this.journal.totalDebit)}`,
              styles: { valign: "middle", halign: "right", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: `${this.formatPrice(this.journal.totalCredit)}`,
              styles: { valign: "middle", halign: "right", lineWidth: 0.1, fontStyle: "bold" },
            },
          ],
        ],
        styles: {
          tableWidth: "auto",
          lineColor: "black",
          font: "times",
          fontSize: 9,
        },
        didDrawCell: data => {
          if (data.row.section === "body" && data.column.dataKey >= 0) {
            let x = data.cell.x + data.cell.width;
            let y = data.cell.y + data.cell.height;
            data.doc.setDrawColor(0);
            data.doc.line(x, data.cell.y, x, y);
          }
        },
      });

      const y2 = doc.lastAutoTable.finalY + 5;

      doc.autoTable({
        margin: { left: 5, right: 5 },
        startY: y2,
        theme: "plain",
        head: [["PREPARED BY :", "CHECKED BY :", "APPROVALS :"]],
        body: [
          [
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
          ],
        ],
        styles: {
          valign: "middle",
          tableWidth: "auto",
          lineWidth: 0.1,
          halign: "left",
          lineColor: "black",
          minCellHeight: 30,
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          tableWidth: "auto",
          minCellHeight: 0,
        },
      });

      this.url = doc.output("datauristring");
    },
  },
  created() {
    this.getJournal();
  },
};
</script>

<style></style>
